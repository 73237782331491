import { classes } from '../base/classes.helpers';
import { occupyBase, occupySprinkles } from './occupy.css';

/**
 * Use this when an element needs to take less or more space in the layout
 * then it would naturally take.
 */
export const occupy = (props: Parameters<typeof occupySprinkles>[0]) => {
	return classes(occupyBase, occupySprinkles(props));
};
