import {
	ColumnsProps,
	GridStackProps,
	ShelfProps,
	arrangeSprinkles,
} from './arrange.css';
import { AlignChildrenProps } from './layout.definitions.css';

type AllProps = Parameters<typeof arrangeSprinkles>[0];
type BaseProps = Omit<
	Parameters<typeof arrangeSprinkles>[0],
	AlignChildrenProps | ShelfProps | ColumnsProps | GridStackProps | 'arrange'
>;

type Layouts = AllProps['layout'];

export type ArrangeProps<Layout extends Layouts> = {
	layout: Layout;
} & (Layout extends string
	? BaseProps &
			(Layout extends 'shelf'
				? Pick<AllProps, ShelfProps>
				: Record<never, never>) &
			(Layout extends 'columns'
				? Pick<AllProps, ColumnsProps>
				: Pick<AllProps, AlignChildrenProps>) &
			(Layout extends 'gridStack'
				? Pick<AllProps, GridStackProps>
				: Record<never, never>)
	: Omit<AllProps, 'layout'>);

/**
 * Arrange children in a layout
 *
 * If you need to arrange children in different kind of layouts
 * depending on media queries, use this component.
 *
 * Most of the time use one of the following UI Primitives:
 *
 * - stack
 * - gridStack
 * - shelf
 * - rows
 * - columns
 */
export const arrange = <Layout extends Layouts>(props: ArrangeProps<Layout>) =>
	arrangeSprinkles(props);
